import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'
import _ from 'lodash'

export default {
  mixins: [eprs],
  methods: {
    async init () {
      if (typeof this.aid === 'undefined') return // No need to prefill form. This is a new assessment

      const promises = []

      // Fetch incident data and prefill form
      if (this.iid) promises.push(this.getIncidentById(this.iid))

      // Fetch data and prefill form
      await Promise.all(promises).then((res) => {
        this.incident = res[0]
        this.assessment = res[0].assessments.find(a => a.assessment_id === this.aid) // [this.aid - 1]

        // Prefill form
        const keys = Object.keys(this.form)

        let prefill = _.filter(this.assessment.data, (o) => {
          return keys.includes(o['incoming key'])
        }).map(o => ({ [o['incoming key']]: o.value }))

        prefill = _.merge(...prefill)

        // m to mm
        if (prefill['dent depth']) prefill['dent depth'] = Math.round(prefill['dent depth'] * 1000 * 1000) / 1000
        if (prefill['gouge depth']) prefill['gouge depth'] = Math.round(prefill['gouge depth'] * 1000 * 1000) / 1000

        this.form = Object.assign({}, this.form, prefill)
      })
    }
  }
}
